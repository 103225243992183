import React from 'react'
import './TextMessage.css'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS, quillSanitizeWhitelist } from '../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import 'react-quill/dist/quill.snow.css'
import Linkify from "react-linkify";

const TextMessage = ({
  content,
  openModal,
  authorType,
  authorName,
  analyticData
}) => {
  const userType = localStorage.getItem('userType')

  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: content.body,
        redirection: content.cta?.[0]?.value,
        ...analyticData
      }
    })
    e.preventDefault()
    openModal(content.cta[0].value)
  }

  const isHTML = /<\/?[a-z][\s\S]*>/i.test(content.body.trim())
  return (
    <>
      <p className="text-content">
        {!isHTML ? (
          <Linkify>{content.body.trim()}</Linkify>
        ) : (
          <div id="inner-content" className="ql-editor" dangerouslySetInnerHTML={{ __html: sanitizeHTML(content.body.trim(), quillSanitizeWhitelist) }} />
        )}
      </p>
      {content.cta && content.cta.length > 0 && (
        <div className="cta-container">
          <a
            href="#"
            className={`cta-button ${authorType === 'ME' ? 'me' : 'not-me'}`}
            onClick={ctaClickHandler}>
            {content.cta[0].label} →
          </a>
        </div>
      )}
    </>
  )
}

export default TextMessage
