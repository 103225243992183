import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import ParentHome from './pages/Home/ParentHome'
import TeacherHome from './pages/Home/TeacherHome'
import Chat from './pages/Chat/Chat'
import Profile from './pages/Profile/Profile'
import ProfilePoh from './pages/Profile/PohProfile'
import Home from './pages/Home/Home'
import NoNetwork from './pages/NoNetwork/NoNetwork'
import useInternetCheck from './hooks/useInternetCheck'
import { initializeSentry, logMessage, setSentryUser } from './sentry'
import { jwtDecode } from 'jwt-decode'
import Mixpanel from './utils/mixpanel'
import { getUserInfo } from './utils/api'
import CentreChat from './pages/Chat/CentreChat/CentreChat'

Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

const App = () => {
  const location = useLocation()

  useInternetCheck(1000)

  useEffect(() => {
    Mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
    initializeSentry()
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    if (token) {
      localStorage.setItem('token', token)
      const decodedToken = jwtDecode(token)
      const userId = decodedToken?.sub
      setSentryUser(userId)
    } else {
      // logMessage('No token found in URL')
    }
  }, [location.search])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/parent-home" element={<ParentHome />} />
      <Route path="/teacher-home" element={<TeacherHome />} />
      <Route path="/chat/:uuid" element={<Chat />} />
      <Route path="/profile/:uuid" element={<Profile />} />
      <Route path="/profile/poh" element={<ProfilePoh />} />
      <Route path="/nonetwork" element={<NoNetwork />} />
      <Route path="/centrechat/:uuid" element={<CentreChat />} />
    </Routes>
  )
}

export default App
