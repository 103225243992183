export const ANALYTIC_EVENTS = {
  VIEWED_CHAT_PAGE: 'Viewed_Chat_Page',
  THREAD_CLICKED: 'Thread_Clicked',
  VIEWED_THREAD: 'Viewed_Thread',
  MESSAGE_SENT: 'Message_Sent',
  MESSAGE_CTA_CLICKED: 'Message_CTA_Clicked',
  PROFILE_CLICKED: 'Profile_Clicked',
  VIEWED_PROFILE_PAGE: 'Viewed_Profile_Page'
}

export const chatTypes = {
  PARENT_CENTRE: 'PARENT_CENTRE',
  PARENT_TEACHER: 'PARENT_TEACHER'
}

export const quillSanitizeWhitelist = {
  allowedTags: [
    'a',
    'p',
    'span',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'b',
    'i',
    'u',
    's',
    'em',
    'strong',
    'strike',
    'sub',
    'sup',
    'hr',
    'br',
    'blockquote',
    'caption',
    'code',
    'pre',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr'
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
    span: ['style'],
    p: ['class'],
    li: ['class'],
    pre: ['class']
  },
  allowedStyles: {
    '*': {
      'background-color': [
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
      ],
      color: [/^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
    }
  },
  allowedClasses: {
    '*': [
      'ql-align-right',
      'ql-align-center',
      'ql-align-justify',
      'ql-code-block',
      'ql-code-block-container',
      'ql-syntax',
      'ql-direction-rtl',
      'ql-font-serif',
      'ql-font-monospace',
      'ql-formula',
      'ql-indent-1',
      'ql-indent-2',
      'ql-indent-3',
      'ql-indent-4',
      'ql-indent-5',
      'ql-indent-6',
      'ql-indent-7',
      'ql-indent-8',
      'ql-size-small',
      'ql-size-large',
      'ql-size-huge'
    ]
  }
}